<template>
    <div>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-alert v-else-if="error" type="error" :value="true">
            {{ error.message }}
        </v-alert>
        <div v-if="o">
            <slot name="object" v-bind:o="o">
                <!-- Fallback content -->
                [MISSING OBJECT SLOT] {{ o }}
            </slot>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import api from '@/services/api';

export default {
  name: 'DetailView',
  props: {
    source: Object,
  },
  data() {
    return {
      o: null,
      loading: true,
      error: null,
      isActive: true,
      isDirty: false,
    };
  },
  created() {
    this.load();
    this.$globalEventBus.$on('object-edited', this.onObjectEdited);
  },
  beforeDestroy() {
    this.$globalEventBus.$off('object-edited', this.onObjectEdited);
  },
  activated() {
    this.isActive = true;
    if (this.isDirty) {
      this.load();
    }
  },
  deactivated() {
    this.isActive = false;
  },
  watch: {
    source(newSource, oldSource) {
      if (!_.isEqual(newSource, oldSource)) {
        this.load();
      }
    },
  },
  methods: {
    onObjectEdited() {
      if (this.isActive) {
        this.load();
      } else {
        this.isDirty = true;
      }
    },
    fetch(params) {
      if (this.source.object) {
        return Promise.resolve(this.source.object);
      }

      let env;
      if (this.source.env) {
        env = api.envs[this.source.env];
      } else {
        env = api.kernel;
      }

      return env.get(this.source.path, {
        ...this.source.query,
        ...params,
      });
    },
    load() {
      this.o = null;
      this.loading = true;
      this.error = null;
      this.isDirty = false;
      this.fetch({}).then((data) => {
        this.loading = false;
        this.error = null;
        if (this.source.postprocess) {
          this.o = this.source.postprocess(data);
        } else {
          this.o = data;
        }
      }, (data) => {
        this.loading = false;
        this.error = data;
      });
    },
  },
};
</script>
